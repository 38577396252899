import api from '../../src/api';
import { signOut } from 'next-auth/client'
import { useRouter } from 'next/router'

export default function LogoutButton({ session, chat = false }) {

  const router = useRouter()

  const logout = async () => {

    console.log( 'chat->', chat )
    console.log( `${chat ? `${process.env.NEXTAUTH_URL}/admin/${router?.query?.slug}` : '/'}` )

    try {
      let logout = await new api.Logout().doMutation(
        null,
        session.user.image.access_token
      );

      if (logout.status === 'TOKEN_REVOKED') signOut({ callbackUrl: `${chat ? `${process.env.NEXTAUTH_URL}/admin/${router?.query?.slug}` : process.env.NEXTAUTH_URL}` })
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {session && (
        <button
          onClick={logout}
          className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-400 lg:text-gray-700 hover:text-white lg:hover:text-gray-700 hover:bg-gray-700 lg:px-4 lg:py-2 lg:text-sm lg:hover:bg-gray-100"
          role="menuitem"
          aria-label="Logout"
          aria-haspopup="true"
        >
          Salir
        </button>
      )}
    </>
  );
}
