import Link from 'next/link'

export default function DropdownPanelDesktop({ children }) {

    return (

        <div className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu">

            

            {children}

        </div>


    )
}