import Head from 'next/head'
import Header from '../header.js'
import Footer from '../Footer'
import DropdownPanelDesktop from '../menu/dropdownPanelDesktop.js'
import DropdownPanelMobile from '../menu/dropdownPanelMobile.js'
import LogoutButton from '../auth/logoutButton.js'
import Zendesk from 'react-zendesk'
const ZENDESK_KEY = process.env.ZENDESK_KEY
import { useEffect, useState } from 'react'
const VALUE_ENV = process.env.VALUE_ENV
import api from '../../src/api/index.js'
import { useRouter } from 'next/router'

export default function Layout({ children, session, chat = false }) {

    const [url,setUrl] = useState('')
    const [liveEvents, setLiveEvents] = useState([])

    const router = useRouter()

    const getLiveEvents = async () => {

       if( router.asPath !== '/home' ) return

        const liveEvents = await new api.GetLiveEvents().doQuery(null, session.user.image.access_token)
        setLiveEvents(liveEvents)
        
    }


    useEffect(() => {
        setUrl(window.location.href)

        getLiveEvents()

    },[])
    const setting = {
        webWidget: {
            setLocale: 'es',
            zIndex: 19
        }
    }

    return (
        <>
            <Head>
                <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no" name="viewport" />
                <meta charSet="utf-8" />
                <link rel="icon" type="image/png" href="/img/favicon.png" />

                <script src="https://addevent.com/libs/atc/1.6.1/atc.min.js" defer></script>
                <script src="https://embed.typeform.com/embed.js"></script>
                <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />

                {/* <script src="http://p3.3playmedia.com/p3sdk.1.10.7.js"></script> */}
                {/* <script src={`${process.env.NEXTAUTH_URL}/js/test.js`}></script> */}

                <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                <title>Boehringer Click</title>
                {
                    VALUE_ENV === 'development' &&
                    <script
                        async
                        src={"https://script.bi-instatag.com?ref=" + encodeURIComponent(url)}
                    ></script>
                }
                {
                    VALUE_ENV === 'production' &&
                    <script
                        async
                        data-api="/_hive"
                        src="/bee.js">
                    </script>
                }
            </Head>


            <Zendesk zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('Zendesk is loaded')} />


            {
                session &&
                <Header session={session} liveEvents={liveEvents}>

                    <DropdownPanelDesktop key="DropdownPanelDesktop">
                        <LogoutButton session={session} chat={chat} />
                    </DropdownPanelDesktop>

                    <DropdownPanelMobile liveEvents={liveEvents} key="DropdownPanelMobile">
                        <LogoutButton session={session} />
                    </DropdownPanelMobile>

                </Header>

            }

            <div className={`bg-gray-100 font-sans antialiased overflow-x-hidden ${session ? 'mt-16 lg:mt-14' : ''} `}>
                {children}
            </div>

            <Footer />

        </>
    )
}