import Link from 'next/link';
import BiLogoWhite from '../public/img/bi.svg';

const Footer = () => {
  return (
    <footer className="w-screen mt-auto footer-container relative flex flex-col items-center py-10">
      <div className="w-11/12">
        <BiLogoWhite width="126" />
      </div>
      <div className="w-11/12">
        <p className="text-sm text-gray-400 font-light my-5 leading-none">
          La información contenida en este material está destinada únicamente al
          profesional de la salud al cual va dirigida. Queda prohibida su
          divulgación, edición, copia o distribución. Cualquier uso no
          autorizado será responsabilidad del profesional de la salud o
          cualquier tercero que tenga acceso a la misma
        </p>
      </div>
      <div className="w-11/12">
        <p className="text-sm text-gray-400 font-light my-5 leading-none">
          La información contenida en esta página web es responsabilidad de las
          personas físicas o morales que la proporcionan, por lo que Boehringer
          Ingelheim o cualquiera de sus empresas relacionadas no responderán por
          cualquier mal uso de la misma por parte del usuario; Así mismo el
          usuario manifiesta haber leído, comprendido y aceptado el Aviso de
          Privacidad que se encuentra a su disposición en la Aplicación y en el
          Sitio Web.
        </p>
      </div>
      <div className="w-11/12 flex flex-col lg:flex-row justify-between items-center">
        <div className="flex justify-start items-center w-full lg:w-3/6">
          <p className="text-xs text-gray-400 font-light">
            <a
              target="_blank"
              href="https://img.boehringerclick.mx/aviso-de-privacidad-2021.pdf"
              className="uppercase cursor-pointer transition ease-in duration-100 hover:text-deep-sky-blue"
            >
              Aviso de Privacidad
            </a>{' '}
            |{' '}
            <a
              target="_blank"
              href="https://img.boehringerclick.mx/TCBI.pdf"
              className="cursor-pointer transition ease-in duration-100 hover:text-deep-sky-blue uppercase"
            >
              Términos de uso y condiciones
            </a>{' '}
            |{' '}
            <Link href="/contacto">
              <a className="cursor-pointer transition ease-in duration-100 hover:text-deep-sky-blue uppercase">
                Contacto
              </a>
            </Link>
          </p>
        </div>
        <div className="w-full lg:w-1/4 mt-5 lg:mt-0">
          <p className="text-xs text-gray-400 font-light text-center lg:text-right">
            &#169; 2021{' '}
            <span className="text-deep-sky-blue cursor-pointer hover:underline">
              <a target="_blank" href="https://www.boehringer-ingelheim.mx/"> Boehringer Ingelheim México</a>
            </span>{' '}
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
