import { useState, useEffect } from 'react'
import Link from 'next/link'
//import LiveButton from '../../components/liveButton.js'
//import { useRouter } from 'next/router'

export default function DropdownPanelMobile({ children, liveEvents }) {

    const [path, setPath] = useState('')

    useEffect(() => {
        setPath(window.location.pathname);
    })

    //const router = useRouter()

    return (

        <div className="lg:hidden bg-navy absolute z-60 w-full">
            
            <div className="pt-4 pb-3 border-t border-gray-700">

                <div className="mt-3 px-2 space-y-1">
    
                    {children}
                </div>
            </div>
        </div>

    )
}